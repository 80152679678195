<template>
  <div
    v-bind="$attrs"
    class="relative rounded-md select-none cursor-pointer group"
    @click="emit('reCrop')"
  >
    <div
      class="mt-auto rounded-md flex h-full w-full select-none overflow-hidden input-border"
    >
      <img
        class="w-full h-full object-cover"
        :class="{ blur }"
        :src="blobUrl"
        alt=""
      />
    </div>
    <div
      class="absolute -right-2 -top-2"
      v-if="(!progress || progress === 1) && !noDelete"
    >
      <NamiButton
        buttonType="secondary"
        :icon="IconX"
        pill
        small
        @click.stop.prevent="emit('remove')"
        block
      />
    </div>
    <div
      class="absolute rounded-b-md w-full bottom-0 z-[1] text-center text-xs font-semibold text-black bg-white select-none whitespace-nowrap overflow-ellipsis overflow-hidden"
    >
      <div
        v-if="progress !== undefined"
        :style="{ width: `${(progress ?? 1) * 100}%` }"
        class="relative w-full h-[2px] bg-nami-nami-blue transition-[width]"
        :class="{
          'bg-nami-nami-blue': !failed && progress && progress < 1,
          'bg-green-500':
            !failed && (progress === 1 || typeof progress === 'undefined'),
          'bg-red-500': failed,
        }"
      ></div>
      <span v-if="showFileName" class="rounded-b-md px-2">
        {{ filename }}
      </span>
    </div>
    <div
      class="opacity-0 group-hover:opacity-100 transition-opacity absolute top-1/2 left-1/2 p-1 rounded-full bg-white/80 dark:bg-neutral-950/80 backdrop-blur-lg shadow"
      style="transform: translateY(-50%) translateX(-50%)"
    >
      <IconEye :size="32" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconX, IconEye } from "@tabler/icons-vue";

interface Props {
  blobUrl?: string;
  filename: string;
  progress?: number;
  failed?: boolean;
  showFileName?: boolean;
  blur?: boolean;
  noDelete?: boolean;
}

interface Events {
  (e: "remove", v: void): void;
  (e: "reCrop", v: void): void;
}

defineProps<Props>();
const emit = defineEmits<Events>();
</script>
